import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Content from "../components/layout/content/content"

const Structure = ({ data: { nodeData } }) => {  
  // we have nodeData from graphQl
  
  return (
  <Layout>
      <Seo title={nodeData.name} />
        <Content node={nodeData} />
    </Layout>    
  )
}
export default Structure

export const query = graphql`
  query($id: String!) {
  nodeData: taxonomyTermStructure(id: { eq: $id }) {
      field_id
      field_id_short
      field_type
      field_name_short
      field_name_full
      field_avg_asl
      field_avg_mention
      field_ecv
      field_iso
      field_num_of_cities
      field_num_of_villages
      field_num_of_municipalities
      field_num_of_areas
      field_num_of_counties
      field_num_of_districts
      field_num_of_regions
      field_regions_list
      field_neighbours_list
      field_neighbours_num
      field_boss_name
      field_boss_gender
      field_boss_political_party
      field_address
      field_phone
      field_email
      field_default_img_copyright
      field_population_total
      field_population_share_male
      field_population_share_female
      field_population_cities
      field_population_villages
      field_population_alltime_string
      field_population_change_1y_share
      field_population_change_5y_share
      field_population_change_alltime_
      field_population_density_total
      field_migration_balance_string
      field_migration_balance_total
      field_natural_increase_string	
      field_natural_increase_total
      field_total_increase_string
      field_total_increase_total
      field_weddings_string
      field_weddings_total
      field_divorces_string
      field_divorces_total
      field_migration_in_permanent_str
      field_migration_in_permanent_tot
      field_migration_out_permanent_st
      field_migration_out_permanent_to
      field_live_birth_string
      field_live_birth_total
      field_dead_string
      field_dead_total
      field_size_total
      field_size_cities
      field_size_villages
      field_soil_agro_total
      field_soil_agro_arable
      field_soil_agro_hops
      field_soil_agro_vineyard
      field_soil_agro_garden
      field_soil_agro_orchard
      field_soil_agro_grassland
      field_nonagro_total
      field_nonagro_forest
      field_nonagro_water_area
      field_nonagro_built_up_area
      field_nonagro_other 
      field_t_beds
      field_t_beds_string
      field_t_beds_1y_share
      field_t_objects
      field_t_objects_string
      field_t_objects_1y_share
      field_t_overstays
      field_t_overstays_string
      field_t_overstays_1y_share
      field_t_tourists
      field_t_tourists_string
      field_t_tourists_1y_share
      field_population_0_14_share
      field_population_15_64_share
      field_population_65_more_share
      field_population_avg_age
      field_population_index_old_age
      field_nationality_string
      field_link_official {
        uri
      }
      field_link_portal {
        uri
      }
      field_link_wiki {
        uri
      }
      relationships {
        field_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1400
                quality: 70
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                )
            }
          }
        }
        field_boss_img {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 200
                quality: 70
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                )
            }
          }
        }
        field_image_coa {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 90
                quality: 70
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                )
            }
          }
        }
        node__municipality {
          title
          path {
            alias
          }
          field_type
          field_geo {
            lat
            lon
            latlon
          }
        }
        field_relations_neighbours {
          name
          path {
            alias
          }
          field_type
          field_boss_name
          field_boss_gender
          relationships {
              field_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 400
                      quality: 60
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      )
                  }
                }
              }
              field_boss_img {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 150
                      quality: 60
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      )
                  }
                }
              }
          }
        }
        field_related_city {
          field_id
          title
          path {
            alias
          }
        }
        parent {
          name
          path {
            alias
          }
          relationships {
            parent {
              name
              path {
                alias
              }
            }
          }
        }
      }
      id
      path {
        alias
      }
      name
    } 
  }
`;
